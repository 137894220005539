<template>
    <div class="date-picker">
        <input min="" class="date-picker__input" v-model="model" ref="input" type="datetime-local" />
    </div>
</template>

<script>
export default {
    name: 'DatePicker',
    props: {
        value: [String, Date],
        default: () => new Date(),
    },
    data() {
        return {};
    },
    computed: {
        model: {
            get() {
                const date = new Date(this.value);
                const year = date
                    .getFullYear()
                    .toString()
                    .padStart(4, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date
                    .getDate()
                    .toString()
                    .padStart(2, '0');
                const hours = date
                    .getHours()
                    .toString()
                    .padStart(2, '0');
                const minutes = date
                    .getMinutes()
                    .toString()
                    .padStart(2, '0');
                return `${year}-${month}-${day}T${hours}:${minutes}`;
            },
            set(val) {
                try {
                    this.$emit('input', new Date(val).toISOString().replace('.000Z', '+00:00'));
                } catch (e) {
                    console.log(e);
                }
            },
        },
    },
};
</script>

<style lang="scss">
@import '@/scss/variables.scss';

.date-picker {
    position: relative;
    &__input {
        border: $form-control-border;
        border-radius: $form-control-border-radius;
        display: block;
        width: 100%;
        padding: 0 $form-control-padding-horizontal;
        height: 40px;
        background: $form-control-bg;
        transition: $form-control-transition;

        &:hover {
            border-color: var(--v-on-surface-medium-base);
        }

        &:focus-within {
            border-color: var(--v-primary-accent-base);
        }
        outline: none;
        &:focus {
            object-fit: none;
            border-color: var(--v-primary-accent-base);
        }
        &.-has-errors {
            border-color: $error;
        }

        &.-disabled {
            background-color: #f3f3f3;
            color: var(--v-on-surface-disabled-base);

            &:hover {
                border-color: $form-control-border-color !important;
            }
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        input::placeholder {
            font-style: italic;
        }
    }
    ::-webkit-datetime-edit {
        cursor: pointer;
    }
    ::-webkit-datetime-edit-text,
    ::-webkit-datetime-edit-month-field,
    ::-webkit-datetime-edit-day-field,
    ::-webkit-datetime-edit-year-field,
    ::-webkit-datetime-edit-hour-field,
    ::-webkit-datetime-edit-minute-field {
        transition: all 0.2s ease;
        border-radius: 4px;
        &:hover {
            color: var(--v-primary-accent-base);
        }
        &:focus {
            background: var(--v-primary-base);
            color: var(--v-surface-base);
        }
    }

    ::-webkit-calendar-picker-indicator {
        cursor: pointer;
    }
}
</style>
