<template>
    <div class="page -column -with-sidebar-right">
        <progress-circular class="entity-item__spinner spinner" v-if="!isDataLoaded && isAvailible"></progress-circular>
        <template v-if="!isAvailible">
            <div class="page-header">
                <h1 class="page-header__text">{{ $t('entities.article.newItem') }}</h1>
            </div>
            <error-block></error-block>
        </template>
        <template v-else-if="isDataLoaded">
            <entity-item-sidebar
                :fields="sidebarBlocks"
                :entity="currentState"
                @change="updateFromSidebar"
            ></entity-item-sidebar>
            <sticky-toolbar>
                <Button
                    icon="check"
                    :disabled="!isSaveAllowed"
                    @click="isEditing ? onUpdateArticle() : onCreateArticle()"
                >
                    {{ $t('entities.save') }}</Button
                >
                <a
                    target="_blank"
                    v-if="isEditing"
                    :href="hasCategory ? `https://${instanceFull.primaryDomain}/${initialState.slug}` : null"
                >
                    <Button type="outlined" :disabled="!hasCategory" icon="visibility">{{
                        $t('entities.view')
                    }}</Button>
                </a>
                <Button
                    v-if="isEditing"
                    :disabled="!hasCategory"
                    @click="
                        $router.push({
                            name: 'Builder Edit Article',
                            params: {
                                categoryId: currentState.categoryId,
                                id: currentState.id,
                                type: 'media',
                            },
                        })
                    "
                    type="outlined"
                    icon="edit"
                    >{{ $t('entities.editInBuilder') }}</Button
                >
                <Button type="outlined" icon="delete" v-if="isEditing" @click="openDeletionModal">
                    {{ $t('entities.remove') }}</Button
                >
            </sticky-toolbar>
            <div class="page-header entity-item__page-header">
                <h1 class="page-header__text">{{ articleId ? currentState.title : $t('entities.article.newItem') }}</h1>
            </div>
            <alert
                type="success"
                class="entity-item__success-alert"
                :class="{ '-break-line': isCreated }"
                v-if="isCreated || isUpdated"
            >
                {{
                    isCreated
                        ? $tc('notifications.created', 1, { entity: this.$tc('entities.article.title', 1) })
                        : $tc('notifications.updated', 1, { entity: this.$tc('entities.article.title', 1) })
                }}
                <template v-if="isCreated">
                    <br />
                    <span @click="onAddMoreOne" class="alert__link">
                        {{ $t('entities.article.alertText.createAnother') }}</span
                    >
                </template>
            </alert>
            <headed-block v-if="articleId" :heading="$t('entities.blocks.content')" class="edit-content-block">
                <template v-slot:header-append
                    ><Button
                        :disabled="!hasCategory"
                        :to="{
                            name: 'Builder Edit Article',
                            params: { categoryId: currentState.categoryId, id: articleId, type: 'media' },
                        }"
                        icon="edit"
                        >{{ $t('entities.editInBuilder') }}</Button
                    ></template
                >
            </headed-block>
            <headed-block class="entity-item__block image-block" :heading="$t('entities.blocks.image')">
                <image-field image-type="article" v-model="currentState.previewImageId" is-popup></image-field>
            </headed-block>
            <template>
                <headed-block
                    class="entity-item__block"
                    v-for="(block, index) in blocks"
                    :style="{ order: index + 1 }"
                    :key="block.title"
                    :heading="block.title"
                >
                    <div class="form">
                        <template v-for="(item, key) in block.items" :class="item.class">
                            <FormItem v-bind="item.props" :name="key" :key="key">
                                <component
                                    :is="item.component"
                                    :key="key === 'slug' ? slugKey : null"
                                    v-model="currentState[key]"
                                    v-bind="item.props || {}"
                                    v-on="getFieldEvents(item.events)"
                                    @update-field="onUpdateFieldValue"
                                    :name="key"
                                    hide-details
                                    outlined
                                    dense
                                ></component>
                            </FormItem>
                        </template>

                        <form-item
                            v-if="block.title === $t('entities.blocks.seo')"
                            :label="$t('entities.article.fields.googlePreview')"
                            class="form__input"
                        >
                            <MetaPreview
                                :header="metaTitle"
                                :link="`https://${instanceFull.primaryDomain}/${currentState.slug}`"
                                :description="metaDescription"
                            />
                        </form-item>
                    </div>
                </headed-block>
            </template>
        </template>
    </div>
</template>

<script>
import { cloneDeep, isEqual, debounce, transform, isObject } from 'lodash';
import EventEmitter from '@/helpers/eventEmitter.ts';
import validateField from '@/helpers/validator';
import StickyToolbar from '@/components/common/StickyToolbar';
import HeadedBlock from '@/components/common/HeadedBlock';
import ErrorBlock from '@/components/common/ErrorBlock';
import FormItem from '@/components/form/item.vue';
import MetaPreview from '@/components/product/ProductMetaPreview';
import { publicUrl } from '@/helpers/values';
import ImageField from '@/components/form/controls/ImageField';
import Button from '@/components/common/Button';
import ProgressCircular from '@/components/common/ProgressCircular';
import Alert from '@/components/common/Alert';
import InputUrl from '@/components/form/controls/InputUrl';
import { mapGetters } from 'vuex';
import Article from '@/entities/blog/Article';
import ArticleService from '@/services/ArticleService';
import SearchForm from '@/components/search/SearchForm';
import EntityItemSidebar from '@/components/common/EntityItemSidebar';
import Select from '@/components/form/controls/Select';
import TagService from '@/services/TagService';
import SelectMultiple from '@/components/form/controls/SelectMultiple';
import AuthorService from '@/services/AuthorService';
import Textarea from '@/components/form/controls/Textarea';
import CategoryService from '@/services/CategoryService';
import DatePicker from '@/components/base/DatePicker';

export default {
    name: 'ArticleItem',
    components: {
        Alert,
        ImageField,
        ErrorBlock,
        HeadedBlock,
        StickyToolbar,
        // DeletionModal,
        MetaPreview,
        FormItem,
        Button,
        ProgressCircular,
        InputUrl,
        SearchForm,
        EntityItemSidebar,
        SelectMultiple,
        Select,
        Textarea,
        DatePicker,
    },

    async created() {
        const isCreated = localStorage.getItem('articleCreated');
        if (isCreated) {
            this.isCreated = true;
            localStorage.removeItem('articleCreated');
        }
        await this.loadData();
        EventEmitter.on('delete-article', this.deleteArticle);
    },

    beforeDestroy() {
        EventEmitter.off('delete-article', this.deleteArticle);
    },
    data() {
        return {
            isDataLoaded: false,
            isSaveAllowed: false,
            currentState: new Article(),
            initialState: null,
            fieldKeys: Object.keys(new Article().fields),
            //      images: [],
            isUpdated: false,
            isCreated: false,
            publicUrl,
            slugKey: 0,
            isAvailible: true,
            tags: [],
            authors: [],
            categories: [],
            currentCategory: {},
        };
    },
    computed: {
        ...mapGetters('config', { sitename: 'getSitename' }),
        ...mapGetters('globalVars', { instanceFull: 'getInstanceFull' }),
        metaAvailableVariables() {
            return {
                '%shop_name%': this.sitename?.value || '',
                '%article_name%': this.currentState.title,
            };
        },
        hasCategory() {
            return this.categories.find((item) => item.id === this.initialState.categoryId);
        },
        currentCategorySlug() {
            return this.categories.find((item) => item.id === this.currentState.categoryId)?.slug;
        },
        articleId() {
            return this.$route.params.id;
        },
        isEditing() {
            return !!this.articleId;
        },
        currency() {
            return this.$store.getters['config/getCurrency'];
        },
        seo() {
            return this.$store.getters['config/getSeo'];
        },
        blocks() {
            return [
                {
                    title: this.$t('entities.blocks.generalInformation'),
                    items: {
                        title: {
                            ...this.currentState.fields.title,
                            events: {
                                input: debounce((title) => {
                                    this.currentState.changeHeadingFromTitle(title);
                                    if (!this.currentState.categoryId) return;
                                    this.currentState.changeSlugFromTitle(
                                        `${this.currentCategorySlug}/${this.currentState.title}`
                                    );
                                }, 1000),
                            },
                        },
                        heading: this.currentState.fields.heading,
                        authorId: {
                            ...this.currentState.fields.authorId,
                            props: {
                                ...this.currentState.fields.authorId.props,
                                items: this.authors,
                            },
                        },
                        publisherId: {
                            ...this.currentState.fields.publisherId,
                            props: {
                                ...this.currentState.fields.publisherId.props,
                                items: this.authors,
                            },
                        },
                        publishedAt: {
                            ...this.currentState.fields.publishedAt,
                            props: {
                                ...this.currentState.fields.publishedAt.props,
                            },
                        },
                        categoryId: {
                            ...this.currentState.fields.categoryId,
                            props: {
                                ...this.currentState.fields.categoryId.props,
                                items: this.categories,
                            },
                            events: {
                                input: () => {
                                    if (!this.currentState.title) return;
                                    this.currentState.slugDisabled = false;
                                    this.currentState.changeSlugFromTitle(
                                        `${this.currentCategorySlug}/${this.currentState.title}`
                                    );
                                },
                            },
                        },
                        tagIds: {
                            ...this.currentState.fields.tagIds,
                            props: {
                                ...this.currentState.fields.tagIds.props,
                                items: this.tags,
                            },
                        },
                        annotation: this.currentState.fields.annotation,
                    },
                },
                {
                    title: this.$t('entities.blocks.seo'),
                    items: {
                        metaTitle: this.currentState.fields.metaTitle,
                        metaDescription: this.currentState.fields.metaDescription,
                        slug: {
                            ...this.currentState.fields.slug,
                            props: {
                                ...this.currentState.fields.slug.props,
                                showCheckbox: !!this.articleId,
                            },
                        },
                    },
                },
            ];
        },
        sidebarBlocks() {
            return [
                {
                    title: this.$t('entities.article.pageStatus'),
                    grouped: true,
                    items: ['enabled', 'hidden', 'noIndex'],
                },
            ];
        },
        metaTitle() {
            return this.currentState.metaTitle
                ? this.currentState.metaTitle
                : this.formatMeta(this.seo.templateArticleTitle.value);
        },
        metaDescription() {
            return this.currentState.metaDescription
                ? this.currentState.metaDescription
                : this.formatMeta(this.seo.templateArticleDescription.value);
        },
    },
    methods: {
        createRedirectOnSave(redirect) {
            this.initialState.redirectOnSave = redirect;
        },
        formatMeta(string) {
            const reg = /%(.*?)%/g;
            return string.replace(reg, (match) => {
                return this.metaAvailableVariables[match];
            });
        },

        async loadData() {
            if (this.articleId) {
                const [error, result] = await ArticleService.getOne(this.articleId);
                if (error) {
                    error.alert();
                    this.isAvailible = false;
                }
                this.currentState = new Article(result);
            }
            await this.getTags();
            await this.getAuthors();
            await this.getCategories();
            this.initialState = cloneDeep(this.currentState);
            this.isDataLoaded = true;
        },

        async getTags() {
            const [error, result] = await TagService.getAll();
            if (error) {
                error.notify();
                return;
            }
            this.tags = result;
        },

        async getAuthors() {
            const [error, result] = await AuthorService.getAll();
            if (error) {
                error.notify();
                return;
            }
            this.authors = result;
        },

        async getCategories() {
            const [error, result] = await CategoryService.getAll();
            if (error) {
                error.notify();
                return;
            }
            this.categories = result;
        },

        async openDeletionModal() {
            this.$store.dispatch('modals/openDeletionModal', { items: this.currentState, entity: 'article' });
        },

        async deleteArticle(items) {
            const [error] = await ArticleService.removeOne(items[0].id);
            if (error) {
                error.notify();
            }
            this.$store.dispatch('modals/closeDeletionModal');
            this.$router.push({ name: 'Article List' });
        },

        // Form methods
        validateForm() {
            let result = true;
            this.fieldKeys.forEach((key) => {
                const errors = this.currentState.fields[key].props.validateFunction
                    ? this.currentState.fields[key].props.validateFunction(this.currentState[key])
                    : validateField(this.currentState[key], this.currentState.fields[key].props.rules);
                if (errors.length !== 0) result = false;
                this.$set(this.currentState.fields[key].props, 'errors', errors);
            });
            if (!result) {
                EventEmitter.trigger('show-noty', {
                    type: 'error',
                    text: this.$t('notifications.validation.error'),
                });
            }
            return result;
        },
        async onUpdateArticle() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await ArticleService.updateOne(this.currentState.data);
            error ? this.failedHandler(result, error) : await this.successUpdateHandler();
        },
        async onCreateArticle() {
            const isFormValid = this.validateForm();
            if (isFormValid === false) return;
            const [error, result] = await ArticleService.createOne(this.currentState.data);
            error ? this.failedHandler(result, error) : this.successCreateHandler();
        },
        successCreateHandler() {
            // Редиректим на редактирование созданного каталога
            localStorage.setItem('articleCreated', true);
            this.isUpdated = false;
            this.$router.push({
                name: 'Edit Article',
                params: { id: this.currentState.id },
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },
        async successUpdateHandler() {
            this.isCreated = false;
            this.isUpdated = true;
            this.slugKey++;
            window.scrollTo({ top: 0, behavior: 'smooth' });
            await this.loadData();
            this.isSaveAllowed = false;
        },
        failedHandler(response, error) {
            error.notify();
            const children = response.data?.errors?.children;
            if (children) {
                this.fieldKeys.forEach((key) => {
                    const errors = children[key] ? children[key].errors : [];
                    if (errors) this.$set(this.currentState.fields[key].props, 'errors', errors);
                });
            }
        },
        getFieldEvents(events) {
            const fieldEvents = {};
            for (const key in events) {
                fieldEvents[key] = events[key]?.bind(this.currentState);
            }
            return fieldEvents;
        },
        onUpdateFieldValue: debounce(function(payload) {
            const { name } = payload;
            const errors = this.currentState.fields[name].props.validateFunction
                ? this.currentState.fields[name].props.validateFunction(this.currentState[name])
                : validateField(this.currentState[name], this.currentState.fields[name].props.rules);
            this.$set(this.currentState.fields[name].props, 'errors', errors);
        }, 600),
        async onAddMoreOne() {
            this.$router.push({
                name: `Create Article`,
            });
            this.currentState = new Article();
            this.isCreated = false;
            this.isUpdated = false;
            await this.loadData();
        },
        difference(object, base) {
            function changes(object, base) {
                return transform(object, function(result, value, key) {
                    if (!isEqual(value, base[key])) {
                        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
                    }
                });
            }
            return changes(object, base);
        },
        updateFromSidebar(state) {
            this.currentState = cloneDeep(state);
        },
    },
    watch: {
        currentState: {
            handler(val) {
                if (this.initialState) {
                    this.isSaveAllowed = !isEqual(val.data, this.initialState.data) && val.slug;
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.edit-content-block {
    order: 2;
    margin-bottom: 64px;
    .block__body {
        display: none;
    }
}
.image-block {
    order: 2;
}
</style>
